import MyOrderHero from "./components/MyOrderHero";

export default function OrderPage() {
  return (
    <div
      style={{
        paddingTop: "var(--nav-margin)",
      }}
      className="margin-container"
    >
      <MyOrderHero />
    </div>
  );
}
