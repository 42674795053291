import { Stack } from "@mantine/core";
import moment from "moment";
import { ReactNode } from "react";

interface IDateStackProps {
  date: string | Date;
  isVisible: boolean;
  label: string;
  format?: string;
  timeRange?: string
}

export default function DateStack({
  date,
  isVisible,
  label,
  format = "dddd DD.MM.YYYY, HH:mm",
  timeRange
}: IDateStackProps) {
  return isVisible ? (
    <Stack spacing={4}>
      <p className="p" style={{ color: "var(--bodyLight)" }}>
        {label}
      </p>
      <p className="p">{moment(date).format(format)}{timeRange && `, ${timeRange}`}</p>
    </Stack>
  ) : null;
}
