import { useId } from "react";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import api from "../apiCalls/api";
import { IOrder } from "../pages/InProgress";

interface INotifications {
  loading: string;
  success: string;
  error: string;
}

export const useUpdateOrder = (notifications?: INotifications) => {
  const notifId = useId();
  const defaultNotifications = {
    loading: notifications?.loading || "Se incarca..",
    success: notifications?.success || "Succes",
    error: notifications?.error || "A aparut o eroare",
  };

  return useMutation<any, Error, any>(
    //@ts-ignore
    (orderToUpdate: IOrder) => api.updateOrder(orderToUpdate),
    {
      onMutate: () =>
        toast.loading(defaultNotifications.loading, {
          id: notifId,
        }),
      onError: () =>
        toast.error(defaultNotifications.error, {
          id: notifId,
        }),
      onSuccess: () =>
        toast.success(defaultNotifications.success, {
          id: notifId,
        }),
    }
  );
};

export default useUpdateOrder;
