import { useLocalStorage } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import classes from "../../modules/layout.module.css";
import { WEBSITE_URL } from "../NavigationLayout";

export default function CookieBanner() {
  const { t, i18n } = useTranslation();
  const locale = i18n.language;

  const [isAccepted, setIsAccepted] = useLocalStorage({
    key: "termsAccepted",
    defaultValue: false,
  });

  const acceptTerms = () => {
    setIsAccepted(true);
  };

  return isAccepted === false ? (
    <div className={`${classes.cookieBannerFlex}`}>
      <div>
        <img
          style={{
            maxWidth: 32,
          }}
          src="/images/icons/cookie.png"
          alt={t("v2.ImageAlts.image")}
        />
        <p style={{ maxWidth: "100%" }} className="p">
          {t("v2.Layout.cookieBanner.message1")}{" "}
          <Link
            target="_blank"
            style={{ fontWeight: 600 }}
            to={`${WEBSITE_URL}${locale}/termeni-si-conditii`}
            className="link"
          >
            {t("v2.Layout.cookieBanner.message2")}
          </Link>{" "}
          {t("v2.Layout.cookieBanner.message3")}
        </p>
      </div>
      <button onClick={acceptTerms} className="btn-pr">
        {t("v2.Layout.cookieBanner.button")}
      </button>
    </div>
  ) : null;
}
