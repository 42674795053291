import { useMantineColorScheme } from "@mantine/core";
import { Link } from "react-router-dom";

export default function Logo() {
  const { colorScheme } = useMantineColorScheme();

  const logoPath =
    colorScheme === "dark" ? "/images/logo/light.png" : "/images/logo/dark.png";
  return (
    <Link to="/verifica1">
      <img style={{ maxWidth: 100 }} src={logoPath} alt="Logo Zarpet" />
    </Link>
  );
}
