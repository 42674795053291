import { Flex, Switch, useMantineColorScheme } from "@mantine/core";
import { IconMoon, IconSun } from "@tabler/icons-react";

export default function ColorSchemeSwitch() {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();

  return (
    <div>
      <Flex gap={40} align="center">
        <Switch
          color={colorScheme === "dark" ? "bg-dark" : undefined}
          style={{
            transition: "var(--tr)",
            border: "1px solid var(--divider)",
            borderRadius: 1000,
          }}
          onChange={(e) => {
            const tempColorScheme = e.target.checked ? "dark" : "light";
            toggleColorScheme(tempColorScheme);
          }}
          checked={colorScheme === "dark"}
          styles={{
            track: {
              background: "none",
              cursor: "pointer",
              border: "none",
            },
            thumb: {
              backgroundColor:
                colorScheme === "light" ? "var(--accent)" : "var(--elevation)",
              border: "none",
            },
          }}
          thumbIcon={
            colorScheme === "light" ? (
              <IconSun
                style={{ width: 14, height: 14 }}
                color="#EDD497"
                stroke={2}
              />
            ) : (
              <IconMoon
                style={{ width: 14, height: 14 }}
                color="#2748B4"
                stroke={2}
              />
            )
          }
          size="md"
        />
      </Flex>
    </div>
  );
}
