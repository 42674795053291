import { useTranslation } from "react-i18next";
import { IItem } from "../interfaces/IOrder";
import classes from "../modules/orderPage.module.css";
import ProductCard from "./ProductCard";
import ProductsTable from "./ProductsTable";

interface IProductsSectionProps {
  products: IItem[];
  total: number;
  currency: string;
}

export default function ProductsSection({
  products,
  total,
  currency,
}: IProductsSectionProps) {
  const { i18n, t } = useTranslation();
  const locale = i18n.language;

  return (
    <div style={{ width: "100%" }}>
      <h4
        className="h4"
        style={{
          marginBottom: "var(--md)",
        }}
      >
       {t("v2.OrderPage.productsSection.title")}
      </h4>
      <div className={classes.productsGrid}>
        {products?.map((item, i) => (
          <ProductCard
            currency={currency}
            index={i + 1}
            locale={locale}
            product={item}
            key={i}
          />
        ))}
      </div>
      <div className={classes.productsTableContainer}>
        <ProductsTable currency={currency} products={products} />
      </div>
      <p className="bt" style={{ textAlign: "right", marginTop: "var(--md)" }}>
      {t("v2.OrderPage.productsSection.total")}:{" "}
        {total.toLocaleString(locale, {
          style: "currency",
          currency,
        })}
      </p>
    </div>
  );
}
