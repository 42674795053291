"use client";

import { Modal, Select, Stack, Textarea } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useDisclosure } from "@mantine/hooks";
import "dayjs/locale/en";
import "dayjs/locale/hu";
import "dayjs/locale/pl";
import "dayjs/locale/ro";
import moment from "moment";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import useUpdateOrder from "../../hooks/useUpdateOrder";
import classes from "../modules/orderPage.module.css";
import { hoursOfDay } from "../utils/dataArrays/dayHours";

interface IDeliveryChanges {
  time: string;
  date: Date;
  reason: string;
}

interface IRequestDateChangeModalProps {
  details?: IDeliveryChanges;
  id: string;
  refetch: () => void;
  isRouteDelivery?: boolean;
}

const initialValues = {
  time: "",
  date: new Date(),
  reason: "",
};

export default function RequestDateChangeModal({
  details,
  id,
  refetch,
  isRouteDelivery,
}: IRequestDateChangeModalProps) {
  const [isOpened, { open, close }] = useDisclosure(false);
  const { t } = useTranslation();
  const notifications = {
    loading: t("v2.notifications.general.loading"),
    success: t("v2.notifications.rescheduleRequest.success"),
    error: t("v2.notifications.general.error"),
  };
  const { mutate: sendRequest, isSuccess } = useUpdateOrder(notifications);
  const { i18n } = useTranslation();
  const locale = i18n.language;
  const [deliveryChanges, setDeliveryChanges] =
    useState<IDeliveryChanges>(initialValues);

  useEffect(() => {
    if (isOpened && details) {
      setDeliveryChanges((prev) => ({
        ...prev,
        reason: details.reason,
        time: details.time,
        date: details.date,
      }));
    }
  }, [isOpened, details]);

  const changeValues = (val: any, field: keyof typeof deliveryChanges) => {
    setDeliveryChanges((prev) => ({
      ...prev,
      [field]: val,
    }));
  };

  const closeAndReset = () => {
    setDeliveryChanges(initialValues);
    close();
  };

  const submitChanges = () => {
    const shouldHaveTime = isRouteDelivery && !deliveryChanges.time;
    if (!deliveryChanges.date && shouldHaveTime) return;
    sendRequest({
      id: id,
      deliveryDateChanged: {
        date: deliveryChanges.date,
        reason: deliveryChanges.reason,
        time: deliveryChanges.time,
      },
      preferredDeliveryDate: true,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      closeAndReset();
      refetch();
    }
  }, [isSuccess]);

  return (
    <>
      <Modal
        maw="400px"
        w="100%"
        radius={20}
        centered
        title={t("v2.OrderPage.orderDetails.infoCard.requestModal.title")}
        opened={isOpened}
        onClose={close}
        style={{
          overflow: "visible",
        }}
      >
        <Stack h="100%" spacing="var(--sm)" w="100%">
          <DateInput
            popoverProps={{
              withinPortal: true,
              zIndex: 1050,
            }}
            value={moment(deliveryChanges.date).toDate()}
            color="var(--accent)"
            minDate={new Date()}
            onChange={(val) => changeValues(val, "date")}
            locale={locale}
            label={t(
              "v2.OrderPage.orderDetails.infoCard.requestModal.inputs.date"
            )}
            styles={{
              label: {
                color: "var(--heading)",
              },
            }}
            classNames={{
              input: "input-style",
            }}
          />
          <Select
            styles={{
              dropdown: {
                zIndex: 5,
              },
            }}
            label={t(
              "v2.OrderPage.orderDetails.infoCard.requestModal.inputs.time"
            )}
            classNames={{
              input: "input-style",
            }}
            data={hoursOfDay}
            value={deliveryChanges.time}
            onChange={(val) => changeValues(val, "time")}
          />
          <Textarea
            value={deliveryChanges.reason}
            onChange={(e) => changeValues(e.target.value, "reason")}
            name="comment"
            w="100%"
            autosize
            minRows={3}
            label={t(
              "v2.OrderPage.orderDetails.infoCard.requestModal.inputs.reason"
            )}
            classNames={{
              input: "textarea-style",
            }}
          />
          <div className="buttonsGroup">
            <button onClick={closeAndReset} className="btn-tr">
              {t(
                "v2.OrderPage.orderDetails.infoCard.requestModal.buttons.cancel"
              )}
            </button>
            <button onClick={submitChanges} className="btn-pr">
              {t(
                "v2.OrderPage.orderDetails.infoCard.requestModal.buttons.send"
              )}
            </button>
          </div>
        </Stack>
      </Modal>

      <button
        onClick={open}
        className={`context-btn ${classes.rescheduleButton}`}
      >
        {t("v2.OrderPage.orderDetails.infoCard.requestModal.buttons.request")}
      </button>
    </>
  );
}
