import { ActionIcon } from "@mantine/core";

import React, { ReactElement, ReactNode } from "react";
import { Link } from "react-router-dom";
interface ISocialMediaBubbleProps {
  icon: ReactNode;
  to: string;
}

export default function SocialMediaBubble({
  icon,
  to,
}: ISocialMediaBubbleProps) {
  const newIcon = React.cloneElement(icon as ReactElement<any>, {
    size: 18,
    color: "var(--accent)",
  });

  return (
    <ActionIcon
      radius={1000}
      style={{
        backgroundColor: "var(--accent20)",
      }}
      size={36}
      to={to}
      component={Link}
      target="_blank"
    >
      {newIcon}
    </ActionIcon>
  );
}
