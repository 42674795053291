"use client";

import { ActionIcon, Tooltip } from "@mantine/core";
import { cloneElement, ReactElement, ReactNode } from "react";
import { Link } from "react-router-dom";

interface IContactActionIconProps {
  to: string;
  icon: ReactNode;
  tooltipLabel: string;
}

export default function ContactActionIcon({
  to,
  icon,
  tooltipLabel,
}: IContactActionIconProps) {
  const formatIcon = cloneElement(icon as ReactElement<any>, {
    size: 24,
    style: {
      flexShrink: 0,
    },
  });

  return (
    <Tooltip
      withArrow
      bg="var(--heading)"
      style={{
        boxShadow: "var(--sh)",
        color: "var(--elevation)",
      }}
      label={tooltipLabel}
    >
      <ActionIcon
        component={Link}
        target="_blank"
        to={to}
        className="outline-ai"
        variant="outline"
      >
        {formatIcon}
      </ActionIcon>
    </Tooltip>
  );
}
