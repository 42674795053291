import { CSSProperties, ReactNode } from "react";

interface ICardWrapperProps {
  children: ReactNode;
  style?: CSSProperties;
  className?: string;
}

export default function CardWrapper({
  children,
  style,
  className,
}: ICardWrapperProps) {
  return (
    <div
      style={{
        backgroundColor: "var(--elevation)",
        padding: 24,
        width: "100%",
        borderRadius: 20,
        boxShadow: "var(--sh)",
        ...style,
      }}
      className={className}
    >
      {children}
    </div>
  );
}
