export default function NotFoundIllustration() {
  return (
    <svg
      style={{
        maxWidth: "700px",
      }}
      width="100%"
      viewBox="0 0 657 304"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="144"
        y="8"
        width="513"
        height="90"
        rx="45"
        fill="var(--divider)"
        fillOpacity="0.2"
      />
      <rect
        y="111"
        width="513"
        height="90"
        rx="45"
        fill="var(--divider)"
        fillOpacity="0.2"
      />
      <rect
        x="300"
        y="214"
        width="251"
        height="90"
        rx="45"
        fill="var(--divider)"
        fillOpacity="0.2"
      />
      <rect
        x="346"
        y="201"
        width="122"
        height="13"
        fill="var(--divider)"
        fillOpacity="0.2"
      />
      <rect
        x="191"
        y="98"
        width="122"
        height="13"
        fill="var(--divider)"
        fillOpacity="0.2"
      />
      <path
        d="M472 78L340.5 4L209 78M472 78V226L340.5 300M472 78L340.5 152M340.5 300L209 226V78M340.5 300V152M209 78L340.5 152"
        stroke="var(--divider)"
        strokeWidth="8"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="12 18"
      />
      <circle cx="236.5" cy="57.5" r="45.5" fill="var(--divider)" />
      <path
        d="M239.315 36.3636L238.761 67.4503H232.859L232.305 36.3636H239.315ZM235.821 80.4048C234.642 80.4048 233.634 79.9929 232.795 79.169C231.957 78.331 231.545 77.3224 231.56 76.1435C231.545 74.9787 231.957 73.9844 232.795 73.1605C233.634 72.3224 234.642 71.9034 235.821 71.9034C236.972 71.9034 237.966 72.3224 238.804 73.1605C239.642 73.9844 240.068 74.9787 240.082 76.1435C240.068 76.9247 239.862 77.642 239.464 78.2955C239.081 78.9347 238.57 79.446 237.93 79.8295C237.291 80.2131 236.588 80.4048 235.821 80.4048Z"
        fill="white"
      />
    </svg>
  );
}
