import { Flex, Stack, TextInput } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";

import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

interface ISearchInputProps {
  withTitle?: boolean;
}

export default function SearchInput({ withTitle }: ISearchInputProps) {
  const navigate = useNavigate();
  const { paramId } = useParams();
  const [searchTerm, setSearchTerm] = useState(paramId || "");
  const { t } = useTranslation();
  const searchOrder = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!searchTerm || searchTerm.toUpperCase() === paramId?.toUpperCase())
      return;
    navigate(`/verifica/${searchTerm}`);
  };

  return (
    <form onSubmit={(e) => searchOrder(e)}>
      <Stack align="center">
        {withTitle && (
          <h3 style={{ textAlign: "center" }} className="h3">
            {t("v2.OrderPage.hero.search.title")}
          </h3>
        )}
        <Flex justify="center" w="100%" align="center" gap="var(--sm)">
          <TextInput
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder={t("v2.OrderPage.hero.search.placeholder")}
            maw={300}
            w="100%"
            rightSection={
              <button type="submit" className="static-btn-pr">
                <IconSearch color="var(--bg)" size={20} stroke={2} />
              </button>
            }
            classNames={{
              input: "search-input-style",
            }}
          />
        </Flex>
      </Stack>
    </form>
  );
}
