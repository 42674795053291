export const theme = {
  fontFamily: `Inter, Arial`,
  fontFamilyMonospace: `Inter, Arial`,
  headings: {
    fontFamily: `Inter, Arial`,
  },
  components: {
    TextInput: {
      styles: (theme: any, params: any) => ({
        input: {
          borderColor: params.error ? "red" : "var(--divider)",
        },
        label: {
          color: "var(--heading)",
        },
      }),
    },
    Modal: {
      styles: (theme: any, params: any) => ({
        title: {
          fontWeight: 500,
          color: "var(--heading)",
        },
        close: {
          color: "var(--heading)",
        },
        content: {
          backgroundColor: "var(--elevation)",
        },
        header: {
          backgroundColor: "var(--elevation)",
          padding: "var(--md)",
          height: "20px",
        },
        body: {
          padding: "var(--md)",
          paddingTop: 0,
        },
      }),
    },
    Textarea: {
      styles: (theme: any, params: any) => ({
        input: {
          borderColor: params.error ? "red" : "var(--divider)",
        },
        label: {
          color: "var(--heading)",
        },
      }),
    },
    Select: {
      styles: (theme: any, params: any) => ({
        dropdown: {
          backgroundColor: "var(--elevation)",
          border: "1px solid var(--divider20)",
          borderRadius: 8,
          boxShadow: "var(--sh)",
        },
        option: {
          backgroundColor: "red",
        },
        label: {
          color: "var(--heading)",
        },
        item: {
          color: "var(--heading)",
          backgroundColor: "var(--elevation)",
          "&[data-hovered]": {
            backgroundColor: "var(--divider20)",
          },
          minHeight: "40px",
          "&:hover": {
            backgroundColor: "var(--divider20)",
          },
          "&[data-selected]": {
            backgroundColor: "var(--accent)",
            "&:hover": {
              backgroundColor: "var(--accent)",
            },
          },
        },
        DateInput: {
          styles: (theme: any, params: any) => ({
            label: {
              color: "var(--heading)",
            },
          }),
        },
      }),
    },
  },
  colors: {
    "bg-dark": [
      "#05141b",
      "#05141b",
      "#05141b",
      "#05141b",
      "#05141b",
      "#05141b",
      "#05141b",
      "#05141b",
      "#05141b",
      "#05141b",
    ],
    "bg-light": [
      "#ffffff",
      "#ffffff",
      "#ffffff",
      "#ffffff",
      "#ffffff",
      "#ffffff",
      "#ffffff",
      "#ffffff",
      "#ffffff",
      "#ffffff",
    ],
  },
};

// export const theme = {
//     // fontFamily: `${inter.className}, Arial`,
//     // fontFamilyMonospace: `${inter.className}, Arial`,
//     // headings: {
//     //   fontFamily: `${inter.className}, Arial`,
//     // },
//     components: {
//       TextInput: TextInput.extend({
//         styles: (_, props) => ({
//           input: {
//             borderColor: props.error ? "red" : "var(--divider)",
//           },
//         }),
//       }),
//       Textarea: Textarea.extend({
//         styles: (_, props) => ({
//           input: {
//             borderColor: props.error ? "red" : "var(--divider)",
//           },
//         }),
//       }),
//       Modal: Modal.extend({
//         styles: (_, props) => ({
//           title: {
//             fontWeight: 500,
//             color: "var(--heading)",
//           },
//           close: {
//             color: "var(--heading)",
//           },
//           content: {
//             backgroundColor: "var(--elevation)",
//           },
//           header: {
//             backgroundColor: "var(--elevation)",
//             padding: "var(--md)",
//             height: "20px",
//           },
//           body: {
//             padding: "var(--md)",
//             paddingTop: 0,
//           },
//         }),
//       }),
//       Select: Select.extend({
//         styles: (_, props) => ({
//           dropdown: {
//             backgroundColor: "var(--elevation)",
//             color: "var(--heading)",
//           },
//           option: {
//             "&:hover": {
//               backgroundColor: "red",
//             },
//           },
//         }),
//       }),
//       Drawer: Drawer.extend({
//         styles: (_, props) => ({
//           title: {
//             fontWeight: 500,
//             color: "var(--heading)",
//           },
//           close: {
//             color: "var(--heading)",
//           },
//           content: {
//             backgroundColor: "var(--elevation)",
//           },
//           header: {
//             backgroundColor: "var(--elevation)",
//             padding: "var(--md)",
//             height: "20px",
//           },
//           body: {
//             padding: "var(--md)",
//             paddingTop: 0,
//           },
//         }),
//       }),
//     },
//   };
