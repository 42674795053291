import { Flex, Loader, Modal, Stack } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useEffect } from "react";
import useAcceptTermsAndConditions from "../../hooks/useAcceptTermsAndConditions";

interface ITermsAndConditionsDrawerProps {
  termsAndConditions?: string;
  id: string;
  agreed?: boolean;
  name: string;
  refetch: () => void;
}

export default function TermsAndConditionsDrawer({
  termsAndConditions,
  id,
  agreed,
  name,
  refetch,
}: ITermsAndConditionsDrawerProps) {
  const [isOpened, { open, close }] = useDisclosure(false);
  const {
    mutate: acceptTerms,
    isSuccess,
    isLoading,
  } = useAcceptTermsAndConditions();

  const submitAccept = () => {
    if (!agreed) {
      acceptTerms(id);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      refetch();
      close();
    }
  }, [isSuccess]);

  return (
    <Modal
      centered
      size={700}
      radius={20}
      withCloseButton={false}
      onClose={close}
      opened={!agreed}
    >
      <div
        className="margin-container"
        style={{ width: "100%", maxWidth: "700px", paddingTop: "var(--lg)" }}
      >
        <Stack spacing="var(--md)">
          <h2 className="h2" style={{ textAlign: "center" }}>
            Termeni si conditii {name}
          </h2>
          <div
            className="terms-custom"
            dangerouslySetInnerHTML={{ __html: termsAndConditions || "" }}
          ></div>
          <Flex justify="flex-end">
            <button onClick={submitAccept} className="btn-pr">
              {isLoading ? <Loader size="27px" color="var(--bg)" /> : "Accept"}
            </button>
          </Flex>
        </Stack>
      </div>
    </Modal>
  );
}
