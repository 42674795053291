import { useTranslation } from "react-i18next";
import { EOrderItemType, IItem } from "../interfaces/IOrder";
import classes from "../modules/orderPage.module.css";
import localeNumber from "../utils/localeNumber";
import CardWrapper from "./general/CardWrapper";

interface IProductsTableProps {
  products: IItem[];
  currency: string;
}

const tableColumns = (t: any) => {
  return [
    {
      label: "#",
    },
    {
      label: t("v2.OrderPage.productsSection.table.name"),
    },
    {
      label: t("v2.OrderPage.productsSection.table.length"),
    },
    {
      label: t("v2.OrderPage.productsSection.table.width"),
    },
    {
      label: t("v2.OrderPage.productsSection.table.surface"),
    },
    {
      label: t("v2.OrderPage.productsSection.table.quantity.short"),
    },
    {
      label: t("v2.OrderPage.productsSection.table.price"),
    },
    {
      label: t("v2.OrderPage.productsSection.table.total"),
    },
  ];
};

export default function ProductsTable({
  products,
  currency,
}: IProductsTableProps) {
  const { i18n, t } = useTranslation();
  const locale = i18n.language;

  return (
    <div style={{ width: "100%" }}>
      <CardWrapper
        style={{
          padding: 0,
        }}
      >
        <table className={classes.productsTable}>
          <thead>
            <tr>
              {tableColumns(t).map((column, i) => (
                <th key={i}>{column.label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {products.map((row, i) => {
              const surface = +((row.length ?? 0) * (row.width ?? 0)).toFixed(
                2
              );
              return (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{row.name}</td>
                  <td>
                    {row.length
                      ? `${localeNumber({
                          value: row.length,
                          locale,
                          style: "decimal",
                        })}m`
                      : "-"}
                  </td>
                  <td>
                    {row.width
                      ? `${localeNumber({
                          value: row.width,
                          locale,
                          style: "decimal",
                        })}m`
                      : "-"}
                  </td>
                  <td>
                    {surface
                      ? `${localeNumber({
                          value: surface,
                          locale,
                          style: "decimal",
                        })}/m²`
                      : "-"}
                  </td>
                  <td>{row.quantity}</td>
                  <td>
                    {row.type === EOrderItemType.CARPET
                      ? `${localeNumber({
                          value: row.pricePerUnit,
                          currency,
                          locale,
                          style: "currency",
                        })}/m²`
                      : `${localeNumber({
                          value: row.pricePerUnit,
                          currency,
                          locale,
                          style: "currency",
                        })}`}
                  </td>
                  <td>
                    {localeNumber({
                      value: row.totalPriceItem,
                      currency,
                      locale,
                      style: "currency",
                    })}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </CardWrapper>
    </div>
  );
}
