"use client";

import { Flex, Rating, Stack, Textarea } from "@mantine/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useUpdateOrder from "../../hooks/useUpdateOrder";
import { IOrder } from "../interfaces/IOrder";
import CardWrapper from "./general/CardWrapper";

interface IFeedbackCardProps {
  order: IOrder;
  refetch: () => void;
}

const initialValues = { rating: 0, comment: "" };

export default function FeedbackCard({ order, refetch }: IFeedbackCardProps) {
  const [feedback, setFeedback] = useState(initialValues);
  const { t } = useTranslation();

  const notifications = {
    loading: t("v2.notifications.general.loading"),
    success: t("v2.notifications.feedback.success"),
    error: t("v2.notifications.general.error"),
  };

  const { mutate: sendFeedback, isSuccess } = useUpdateOrder(notifications);

  useEffect(() => {
    if (order) {
      setFeedback((prev) => ({
        ...prev,
        rating: order.feedback?.rating || 0,
        comment: order.feedback?.comment || "",
      }));
    }
  }, [order]);

  const resetForm = () => {
    setFeedback(initialValues);
  };

  const submitFeedback = () => {
    if (!feedback.comment || !feedback.rating || order.feedbackSubmitted)
      return;
    sendFeedback({
      id: order.generatedTicketId,
      feedback: {
        rating: feedback.rating,
        comment: feedback.comment,
      },
      feedbackSubmitted: true,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      refetch();
    }
  }, [isSuccess]);

  return (
    <div style={{ width: "100%" }}>
      <h4
        className="h4"
        style={{
          marginBottom: "var(--md)",
        }}
      >
        {t("v2.OrderPage.feedbackSection.title")}
      </h4>
      <CardWrapper>
        <Stack w="100%" spacing="var(--sm)">
          <div>
            <p className="m_8fdc1311 mantine-InputWrapper-label mantine-Textarea-label">
              {t("v2.OrderPage.feedbackSection.inputs.score")}
            </p>
            <Rating
              name="rating"
              readOnly={order.feedbackSubmitted}
              onChange={(val) => {
                if (!order.feedbackSubmitted) {
                  setFeedback((prev) => ({
                    ...prev,
                    rating: val,
                  }));
                }
              }}
              value={feedback.rating}
              color="var(--accent)"
              size="xl"
              w="fit-content"
            />
          </div>
          <Textarea
            name="comment"
            readOnly={order.feedbackSubmitted}
            onChange={(e) =>
              setFeedback((prev) => ({
                ...prev,
                comment: e.target.value,
              }))
            }
            value={feedback.comment}
            w="100%"
            autosize
            minRows={8}
            placeholder={t("v2.OrderPage.feedbackSection.inputs.placeholder")}
            label={t("v2.OrderPage.feedbackSection.inputs.message")}
            classNames={{
              input: "textarea-style",
            }}
          />
          {!order.feedbackSubmitted ? (
            <Flex justify="flex-end">
              <button onClick={submitFeedback} className="btn-pr">
                {t("v2.OrderPage.feedbackSection.button")}
              </button>
            </Flex>
          ) : (
            <p className="p" style={{ color: "var(--green)" }}>
              {t("v2.OrderPage.feedbackSection.feedbackSent")}
            </p>
          )}
        </Stack>
      </CardWrapper>
    </div>
  );
}
