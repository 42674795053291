import "@fontsource/dm-mono";
import "@fontsource/dm-sans";
import {
  ColorScheme,
  ColorSchemeProvider,
  MantineProvider,
} from "@mantine/core";
import { DatesProvider } from "@mantine/dates";
import { useColorScheme, useLocalStorage } from "@mantine/hooks";
import { Analytics } from "@vercel/analytics/react";
import "dayjs/locale/hu";
import "dayjs/locale/ro";
import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import {
  I18nextProvider,
  useTranslation,
  withTranslation,
} from "react-i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import i18n from "./i18n";
import CookiePolicy from "./pages/CookiePolicy";
import InProgress from "./pages/InProgress";
import PrivacyPolicy from "./pages/PrivatePolicy";
import { TermsAndConditions } from "./pages/TermsAndConditions";
import OrderDetailsPage from "./v2/OrderDetailsPage";
import OrderPage from "./v2/OrderPage";
import NavigationLayout from "./v2/layout/NavigationLayout";
import { theme } from "./v2/layout/theme";
const queryClient = new QueryClient();
const App: React.FC = () => {
  const [isInIframe, setIsInIframe] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const inIframe = window.self !== window.top;
    setIsInIframe(inIframe);
  }, []);

  const preferedColorScheme = useColorScheme();
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: "zarpet-scheme",
    defaultValue: preferedColorScheme,
    getInitialValueInEffect: true,
  });
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", colorScheme);
  }, [colorScheme]);

  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      {/* @ts-ignore */}
      <MantineProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <I18nextProvider i18n={i18n}>
            <DatesProvider
              settings={{ locale: "en", firstDayOfWeek: 0, weekendDays: [0] }}
            >
              <Router>
                <div>
                  <NavigationLayout>
                    <Analytics />
                    <Toaster position="top-right" />
                    <Routes>
                      <Route
                        path="*"
                        element={<Navigate to="/verifica" replace />}
                      />
                      <Route path="/v" Component={OrderPage} />
                      <Route path="/v/:paramId" Component={OrderDetailsPage} />
                      <Route path="/verifica" Component={OrderPage} />
                      <Route
                        path="/verifica/:paramId"
                        Component={OrderDetailsPage}
                      />
                      <Route path="/verifica-v1" Component={InProgress} />
                      <Route
                        path="/verifica-v1/:paramId"
                        Component={InProgress}
                      />
                      <Route
                        path="/termeni-si-conditii"
                        Component={TermsAndConditions}
                      />
                      <Route
                        path="/politica-de-confidentialitate"
                        Component={PrivacyPolicy}
                      />
                      <Route
                        path="/politica-de-cookies"
                        Component={CookiePolicy}
                      />
                    </Routes>
                  </NavigationLayout>
                </div>
              </Router>
            </DatesProvider>
          </I18nextProvider>
        </QueryClientProvider>
      </MantineProvider>
    </ColorSchemeProvider>
  );
};

export default withTranslation()(App);
