import { Select } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconWorld } from "@tabler/icons-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const selectData = (t: any) => {
  const languages = [
    { label: t("v2.Language.ro"), value: "RO" },
    { label: t("v2.Language.en"), value: "EN" },
    { label: t("v2.Language.hu"), value: "HU" },
    { label: t("v2.Language.pl"), value: "PL" },
  ];

  return languages;
};

export default function LanguageSwitch() {
  const { i18n, t } = useTranslation();
  const [selected, setSelected] = useState(i18n.language.toUpperCase());
  const isMobileXs = useMediaQuery("(max-width: 375px)");
  const changeLanguage = (lang: string) => {
    localStorage.setItem("language-client", lang.toLocaleLowerCase());
    setSelected(lang);
    i18n.changeLanguage(lang.toLocaleLowerCase());
  };

  return (
    <Select
      value={selected}
      w={isMobileXs ? 60 : 140}
      onChange={(val) => changeLanguage(val!)}
      icon={<IconWorld size={20} stroke={1.5} color="var(--bodyLight)" />}
      styles={{
        input: {
          background: "none",
          color: "var(--bodyLight)",
          border: "none",
          fontSize: 16,
          display: "flex",
          alignItems: "center",
          lineHeight: "100%",
        },
        dropdown: {
          minWidth: "140px",
        },
      }}
      data={selectData(t)}
    />
  );
}
