import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import CookieBanner from "./components/CookieBanner";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";

interface INavigationLayoutProps {
  children: ReactNode;
}

export const WEBSITE_URL = process.env.REACT_APP_WEBSITE_URL;

export const navigation = (locale: string, t: any) => {
  return [
    {
      to: `${WEBSITE_URL}${locale}`,
      label: t("v2.Layout.navigation.homePage"),
    },
    {
      to: `${WEBSITE_URL}${locale}/despre-noi`,
      label: t("v2.Layout.navigation.aboutPage"),
    },
    {
      to: `${WEBSITE_URL}${locale}/planuri`,
      label: t("v2.Layout.navigation.plansPage"),
    },
    {
      to: `${WEBSITE_URL}${locale}/contact`,
      label: t("v2.Layout.navigation.contactPage"),
    },
  ];
};

export default function NavigationLayout({ children }: INavigationLayoutProps) {
  const { t } = useTranslation();
  return (
    <div className="page-container">
      <Navbar />
      <CookieBanner />
      {children}
      <div style={{ marginTop: "auto" }}>
        <Footer />
      </div>
    </div>
  );
}
