import { Avatar, Divider, Flex, Stack } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { EOrderItemType, IItem } from "../interfaces/IOrder";
import localeNumber from "../utils/localeNumber";
import CardWrapper from "./general/CardWrapper";

interface IProductCardProps {
  product: IItem;
  index: number;
  locale: string;
  currency: string;
}

export default function ProductCard({
  product,
  index,
  locale,
  currency,
}: IProductCardProps) {
  const { t } = useTranslation();
  const surface = +((product.length ?? 0) * (product.width ?? 0)).toFixed(2);
  return (
    <CardWrapper style={{ paddingInline: 0 }}>
      <Stack spacing="var(--sm)">
        <Flex align="center" gap="var(--xs)" px="var(--md)">
          <Avatar
            styles={{
              placeholder: {
                color: "var(--accent)",
                backgroundColor: "var(--accent10)",
              },
            }}
            size={40}
            radius={8}
          >
            #{index}
          </Avatar>
          <p className="bt">{product.name}</p>
        </Flex>
        <Stack px="var(--md)" spacing="var(--sm)">
          {product.type === EOrderItemType.CARPET && (
            <ProductCardDetail
              label={t("v2.OrderPage.productsSection.cards.dimensions")}
              value={`${localeNumber({
                value: product.length ?? 0,
                locale,
                style: "decimal",
              })} x ${localeNumber({
                value: product.width ?? 0,
                locale,
                style: "decimal",
              })}m`}
            />
          )}
          {product.type === EOrderItemType.CARPET && (
            <ProductCardDetail
              label={t("v2.OrderPage.productsSection.table.surface")}
              value={
                surface
                  ? `${localeNumber({
                      value: surface,
                      locale,
                      style: "decimal",
                    })}m²`
                  : "-"
              }
            />
          )}
          <ProductCardDetail
            label={t("v2.OrderPage.productsSection.table.price")}
            value={
              product.type === EOrderItemType.CARPET
                ? `${localeNumber({
                    value: product.pricePerUnit,
                    currency,
                    locale,
                    style: "currency",
                  })}/m²`
                : `${localeNumber({
                    value: product.pricePerUnit,
                    currency,
                    locale,
                    style: "currency",
                  })}`
            }
          />
          <ProductCardDetail
            label={t("v2.OrderPage.productsSection.table.quantity.long")}
            value={product.quantity?.toString() || "1"}
          />
        </Stack>
        <Divider color="var(--divider40)" />
        <Flex px="var(--md)" align="center" justify="space-between">
          <p className="p">{t("v2.OrderPage.productsSection.table.total")}:</p>
          <p className="bt">
            {localeNumber({
              value: product.totalPriceItem,
              currency,
              locale,
              style: "currency",
            })}
          </p>
        </Flex>
      </Stack>
    </CardWrapper>
  );
}

interface IProductCardDetailProps {
  label: string;
  value: string;
}

export function ProductCardDetail({ label, value }: IProductCardDetailProps) {
  return (
    <Flex align="center" justify="space-between">
      <p className="p" style={{ color: "var(--bodyLight)" }}>
        {label}:
      </p>
      <p className="p">{value}</p>
    </Flex>
  );
}
