import { Stack, useMantineColorScheme } from "@mantine/core";
import { useTranslation } from "react-i18next";
import SearchInput from "./SearchInput";

export default function MyOrderHero() {
  const { colorScheme } = useMantineColorScheme();
  const { t } = useTranslation();
  return (
    <div
      style={{
        width: "100%",
      }}
      className="margin-container"
    >
      <Stack align="center" w="100%" spacing="var(--xl)">
        <Stack align="center" w="100%" spacing="var(--md)">
          <h1 style={{ textAlign: "center" }} className="h1">
            {t("v2.OrderPage.hero.title")}
          </h1>
          <p style={{ textAlign: "center" }} className="p">
            {t("v2.OrderPage.hero.subtext")}
          </p>
        </Stack>
        <div
          style={{
            width: "100%",
          }}
        >
          <SearchInput />
        </div>

        <img
          style={{
            width: "100%",
            scale: 1.1,
            filter: `brightness(${colorScheme === "dark" ? 0.8 : 1})`,
          }}
          src="/images/my-order-img.png"
          alt={t("v2.ImageAlts.orderPage")}
        />
      </Stack>
    </div>
  );
}
