import { Flex } from "@mantine/core";
import { useEffect, useState } from "react";
import classes from "../../modules/layout.module.css";
import ColorSchemeSwitch from "./ColorSchemeSwitch";
import LanguageSwitch from "./LanguageSwitch";
import Logo from "./Logo";

export default function Navbar() {
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 40) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeBackground);
    return () => {
      window.removeEventListener("scroll", changeBackground);
    };
  }, []);

  return (
    <div
      style={{
        background: navbar ? "var(--bg)" : "none",
        transition: "var(--tr)",
      }}
      className={classes.navigationFloatingContainer}
    >
      <div className="margin-container">
        <div style={{ width: "100%" }} className={classes.navLinks}></div>
        <nav className={classes.navigation}>
          <Logo />
          <Flex align="center" gap="var(--sm)">
            <LanguageSwitch />
            <ColorSchemeSwitch />
          </Flex>
        </nav>
      </div>
    </div>
  );
}
